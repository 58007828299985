import { sanitizeFilename } from '~/assets/javascript/utils/string';

export function streamDownload(name, extension, data) {
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(new Blob([data]));
  link.target = '_blank';
  link.download = sanitizeFilename(name, extension);

  link.click();
}
